import PropTypes from "prop-types"
import React from "react"
import ApplicationLogo from "./ApplicationLogo"

function Header({ siteTitle, podcast }) {
  return (
    <nav className="flex flex-wrap items-center justify-between pr-6 py-6 m-auto mb-6 max-w-6xl ">
      <div className="items-center flex-shrink-0 mr-6 hidden sm:flex">
        <ApplicationLogo siteTitle={siteTitle} />
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 sm:hidden">
        <ApplicationLogo siteTitle={siteTitle} small={true} />
      </div>
      <div
        className={`block flex-grow sm:flex items-center justify-end w-auto`}
      >
        <div className="text-sm lg:flex-grow"></div>
        <div className="flex items-center justify-end flex-grow">
          <a
            href="https://app.onlinesalesscript.com/login"
            target="_blank"
            rel="noreferrer"
            className="Button-login"
          >
            Log In
          </a>
          <a
            href={`https://app.onlinesalesscript.com/${
              podcast ? "podcast" : "register"
            }`}
            target="_blank"
            rel="noreferrer"
            className="Button-retro Button-retro_dark "
          >
            Sign Up - Free
          </a>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
