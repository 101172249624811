import React from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

export default function ApplicationLogo({ siteTitle, small }) {
  return (
    <div
      className={classNames("flex justify-center  mb-0 px-2", {
        "items-center": !small,
        "items-start": small,
        "flex-col": !small,
        "flex-row": small,
      })}
    >
      {small ? (
        // <img src="/images/ss-icon.png" alt="Logo" width="35" height="35"></img>
        <StaticImage
          src="../images/mobile-icon.png"
          alt="Invitation"
          height={35}
          className="ml-4"
        />
      ) : (
        <>
          <span className="font-bold text-xl mb-1 tracking-wide">
            {siteTitle}
          </span>

          <div className="flex justify-between px-4 mb-3">
            <div className="rounded-lg mr-3 h-4 w-8 bg-gradient-to-r from-problemDark to-problemLight"></div>
            <div className="rounded-lg mr-3 h-4 w-6 bg-gradient-to-r from-solutionDark to-solutionLight "></div>
            <div className="rounded-lg mr-3 h-4 w-5 bg-gradient-to-r from-stakesNegDark to-stakesNegLight"></div>
            <div className="rounded-lg mr-3 h-4 w-11 bg-gradient-to-r from-stakesPosDark to-stakesPosLight"></div>
            <div className="rounded-lg h-4 w-9 bg-gradient-to-r from-actionDark to-actionLight"></div>
          </div>
        </>
      )}
    </div>
  )
}
